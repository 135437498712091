import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { fire } from "../Firebase";
import Navbar from "../Components/Navbar";
import classes from "./Coupon.module.css";

const Coupon = () => {
  const [couponValue, setCouponValue] = useState({});
  const coupon = useRef();

  useEffect(() => {
    fire.child("coupons").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setCouponValue({ ...snapshot.val() });
      } else {
        setCouponValue({});
      }
    });
    return () => {
      setCouponValue({});
      //   setLoader(false);
    };
  }, []);

  const addCoupon = (e) => {
    e.preventDefault();
    const state = {
      coupon: coupon.current.value,
    };
    if (coupon.current.value.trim().length === 0) {
      toast.error("Enter Coupon");
    } else {
      fire.child(`coupons`).push(state, (err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Coupon Added Successfully");
          coupon.current.value = "";
        }
      });
    }
  };

  const deleteCoupon = (id) => {
    if (
      window.confirm("Are you sure that you wanted to delete that Coupon ?")
    ) {
      fire.child(`coupons/${id}`).remove((err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Coupon Deleted Successfully");
        }
      });
    }
  };
  return (
    <>
      <Navbar />

      <div className={classes.addCoupon}>
        <form onSubmit={addCoupon}>
          <input
            type="text"
            id="text"
            placeholder="Create Coupon"
            ref={coupon}
          />
          <button>Add Coupon</button>
        </form>
      </div>

      <div className={classes.showCoupon}>
        {Object.keys(couponValue).map((id, index) => {
          return (
            <div key={id} className={classes.coupon}>
              <p>{index + 1}.</p>
              <h3>{couponValue[id].coupon}</h3>
              <button type="button" onClick={() => deleteCoupon(id)}>
                Delete
              </button>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Coupon;
