import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loginActions } from "../Store/login";
import classes from "./User.module.css";
import { fire } from "../Firebase";
import { FaUsers } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import Navbar from "../Components/Navbar";

const EntAdmin = () => {
  const [data, setData] = useState({});
  const [allData, setAllData] = useState({});
  const [activeOrders, setActiveOrders] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const userPresent = localStorage.getItem("username");
    if (userPresent) {
      dispatch(loginActions.isUserPresent({ user: userPresent }));
    }
  }, [dispatch]);

  // Getting All Signed Up Users

  useEffect(() => {
    fire.child("entSignupUsers").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, []);

  const allUsers = Object.values(data);
  const allOrders = Object.values(allData);

  // Getting All Users Data

  useEffect(() => {
    fire.child("entAllOrders").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setAllData({ ...snapshot.val() });
      } else {
        setAllData({});
      }
    });
    return () => {
      setAllData({});
    };
  }, []);

  // Getting a Particular Collection of Signedup Users

  const getColl = (selectedEmail) => {
    const filteredOrder = Object.values(allData).filter((filterOrder) => {
      return filterOrder.userEmail === selectedEmail;
    });
    setActiveOrders(filteredOrder);
  };

  return (
    <>
      <Navbar />
      <div className={classes.profile}>
        <h3>
          <FaUsers />
          {allUsers.length} Users
        </h3>
        <h3>
          <TbTruckDelivery />
          {allOrders.length} Orders Onboard
        </h3>
      </div>
      <div className={classes.container}>
        <table className={classes.userTable}>
          <thead>
            <tr className={classes.signupHead}>
              <th>No.</th>
              <th>Users</th>
            </tr>
          </thead>
          <tbody className={classes.userBody}>
            {Object.keys(data).map((id, index) => {
              return (
                <tr key={id} className={classes.userName}>
                  <th scope="row">{index + 1}</th>
                  <td onClick={() => getColl(data[id].email)}>
                    {data[id].email}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <table className={classes.styledTable}>
          <thead>
            <tr>
              <th>No.</th>
              <th>Date Time</th>
              <th>Pickup Address</th>
              <th>Pickup No</th>
              <th>Pickup Reference</th>
              <th>Drop Address</th>
              <th>Drop No</th>
              <th>Drop Reference</th>
              <th>Package Reference</th>
              <th>Secure Package</th>
              <th>Order Status</th>
              <th>Price</th>
              <th>Delivery Boy</th>
            </tr>
          </thead>

          <tbody>
            {activeOrders.map((id, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{id.dateTime}</td>
                  <td>{id.pickAdd}</td>
                  <td>{id.pickNo}</td>
                  <td>{id.pickRem}</td>
                  <td>{id.dropAdd}</td>
                  <td>{id.dropNo}</td>
                  <td>{id.dropRem}</td>
                  <td>{id.packRef}</td>
                  <td>{id.secPack}</td>
                  <td>{id.status}</td>
                  <td>{id.price}</td>
                  <td>{id.deliveryBoy}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EntAdmin;
