import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../Admin/Edit.module.css";
import { fire } from "../Firebase";
import { toast } from "react-toastify";
import Navbar from "../Components/Navbar";

const initialState = {
  name: "",
  number: "",
  address: "",
  email: "",
  password: "",
  wallet: "",
};
const EditWallet = () => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const { name, number, address, email, password, wallet } = state;

  useEffect(() => {
    fire.child(`entSignupUsers`).on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }

    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!name || !number || !address || !email || !password || !wallet) {
      toast.error("Please provide value in each input field");
    } else {
      fire.child(`entSignupUsers/${id}`).set(state, (err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Data Updated Successfully");
        }
      });
      setTimeout(() => navigate("/wallet"), 1000);
    }
  };

  return (
    <>
      <Navbar />
      <div style={{ marginTop: "100px" }}>
        <form onSubmit={submitHandler} className={classes.courierDetails}>
          <div className={classes.total}>
            <div
              style={{
                textAlign: "left",
                marginBottom: "20px",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              <label className={classes.pick}>Users Wallet</label>
            </div>
          </div>
          <div className={classes.orderStatus}>
            <p className={classes.pick}>Wallet</p>
            <input
              type="number"
              id="number"
              placeholder="Wallet Balance"
              className={classes.add}
              name="wallet"
              value={wallet || ""}
              onChange={handleInputChange}
            />
          </div>

          <div className={classes.total}>
            <button
              type="submit"
              style={{ backgroundColor: "#ff4141", color: "white" }}
            >
              Update Status
            </button>
          </div>

          <div className={classes.total}>
            <button
              type="button"
              style={{ backgroundColor: "#ff4141", color: "white" }}
              onClick={() => {
                setShowMore(!showMore);
              }}
            >
              {showMore ? "Hide Data" : "Show Data"}
            </button>
          </div>

          {showMore && (
            <>
              <div className={classes.orderStatus}>
                <p className={classes.pick}>Name</p>
                <input
                  type="text"
                  id="text"
                  placeholder="Users Name"
                  className={classes.remarks}
                  name="name"
                  value={name || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className={classes.orderStatus}>
                <p className={classes.pick}>Number</p>
                <input
                  type="number"
                  id="number"
                  placeholder="Enter Number"
                  className={classes.remarks}
                  name="number"
                  value={number || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className={classes.orderStatus}>
                <p className={classes.pick}>Address</p>
                <input
                  type="text"
                  id="text"
                  placeholder="Enter Address"
                  className={classes.remarks}
                  name="address"
                  value={address || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className={classes.orderStatus}>
                <p className={classes.pick}>Email</p>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter Email"
                  className={classes.remarks}
                  name="email"
                  value={email || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className={classes.orderStatus}>
                <p className={classes.pick}>Password</p>
                <input
                  type="text"
                  id="text"
                  placeholder="Enter Password"
                  className={classes.remarks}
                  name="password"
                  value={password || ""}
                  onChange={handleInputChange}
                />
              </div>{" "}
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default EditWallet;
