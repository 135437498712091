import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../Admin/Edit.module.css";
import { fire } from "../Firebase";
import { toast } from "react-toastify";
import Navbar from "../Components/Navbar";

const initialState = {
  name: "",
  number: "",
  email: "",
  password: "",
  wallet: "",
  earning: "",
  approval: "",
};
const EditDriver = () => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});
  const [showMore, setShowMore] = useState(false);
  const [showApproval, setShowApproval] = useState(true);
  const navigate = useNavigate();
  const { id } = useParams();

  const { name, number, email, password, wallet, earning, approval } = state;

  useEffect(() => {
    fire.child(`deliveryPartners`).on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }

    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!name || !number || !email || !password || !wallet || !earning) {
      toast.error("Please provide value in each input field");
    } else {
      fire.child(`deliveryPartners/${id}`).set(state, (err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Wallet Updated Successfully");
        }
      });
      setTimeout(() => navigate("/drivers"), 1000);
    }
  };
  const approvalHandle = () => {
    if (approval === "true") {
      state.approval = "false";
      setShowApproval(false);
    } else {
      state.approval = "true";
      setShowApproval(true);
    }
  };
  return (
    <>
      <Navbar />
      <div>
        <form onSubmit={submitHandler} className={classes.courierDetails}>
          <div className={classes.total}>
            <div
              style={{
                textAlign: "center",
                marginBottom: "20px",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              <label className={classes.pick} style={{ fontSize: "26px" }}>
                {name}'s Wallet
              </label>
            </div>
          </div>
          <div className={classes.orderStatus} style={{ textAlign: "center" }}>
            <p
              className={classes.pick}
              style={{ display: "inline", fontSize: "20px" }}
            >
              Wallet -
            </p>
            <input
              type="number"
              id="number"
              placeholder="Wallet Balance"
              className={classes.add}
              name="wallet"
              value={wallet || ""}
              onChange={handleInputChange}
              style={{ width: "200px" }}
            />
          </div>
          <div className={classes.orderStatus} style={{ textAlign: "center" }}>
            <p
              className={classes.pick}
              style={{ display: "inline", fontSize: "20px" }}
            >
              Earning
            </p>
            <input
              type="number"
              id="number"
              placeholder="Total Balance"
              className={classes.add}
              name="earning"
              value={earning || ""}
              onChange={handleInputChange}
              style={{ width: "200px" }}
            />
          </div>

          <div className={classes.total}>
            <button
              type="submit"
              style={{
                backgroundColor: "#ff4141",
                color: "white",
                width: "180px",
              }}
            >
              Update
            </button>
            <button
              type="button"
              onClick={approvalHandle}
              style={{
                border: !showApproval ? "1px solid red" : "1px solid silver",
              }}
            >
              {approval === "true" ? "Deny" : "Approve"}
            </button>
          </div>

          <div className={classes.total}>
            <button
              type="button"
              style={{ backgroundColor: "#ff4141", color: "white" }}
              onClick={() => {
                setShowMore(!showMore);
              }}
            >
              {showMore ? "Hide Data" : "Show Data"}
            </button>
          </div>

          {showMore && (
            <>
              <div
                className={classes.orderStatus}
                style={{ textAlign: "center" }}
              >
                <p
                  className={classes.pick}
                  style={{ display: "inline", fontSize: "20px" }}
                >
                  Name -
                </p>
                <input
                  type="text"
                  id="text"
                  placeholder="Users Name"
                  className={classes.remarks}
                  name="name"
                  value={name || ""}
                  onChange={handleInputChange}
                  style={{ width: "200px" }}
                />
              </div>
              <div
                className={classes.orderStatus}
                style={{ textAlign: "center" }}
              >
                <p
                  className={classes.pick}
                  style={{ display: "inline", fontSize: "20px" }}
                >
                  Number -
                </p>
                <input
                  type="number"
                  id="number"
                  placeholder="Enter Number"
                  className={classes.remarks}
                  name="number"
                  value={number || ""}
                  onChange={handleInputChange}
                  style={{ width: "200px" }}
                />
              </div>
              <div
                className={classes.orderStatus}
                style={{ textAlign: "center" }}
              >
                <p
                  className={classes.pick}
                  style={{ display: "inline", fontSize: "20px" }}
                >
                  Email -
                </p>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter Email"
                  className={classes.remarks}
                  name="email"
                  value={email || ""}
                  onChange={handleInputChange}
                  style={{ width: "200px" }}
                />
              </div>
              <div
                className={classes.orderStatus}
                style={{ textAlign: "center" }}
              >
                <p
                  className={classes.pick}
                  style={{ display: "inline", fontSize: "20px" }}
                >
                  Password -
                </p>
                <input
                  type="text"
                  id="text"
                  placeholder="Enter Password"
                  className={classes.remarks}
                  name="password"
                  value={password || ""}
                  onChange={handleInputChange}
                  style={{ width: "200px" }}
                />
              </div>{" "}
            </>
          )}
        </form>
      </div>
    </>
  );
};

export default EditDriver;
