import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loginActions } from "../Store/login";
import classes from "./Edit.module.css";
import { fire } from "../Firebase";
import { toast } from "react-toastify";
import Loader from "../Components/Loader";
import Navbar from "../Components/Navbar";

const DeliveredOrder = () => {
  const [data, setData] = useState({});
  const [entData, setEntData] = useState({});
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [entDeliveredOrders, setEntDeliveredOrders] = useState([]);
  const [userOrders, setUserOrders] = useState(true);
  const [entOrders, setEntOrders] = useState(false);
  const dispatch = useDispatch();
  const [getOrder, setGetOrder] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const userPresent = localStorage.getItem("username");
    if (userPresent) {
      dispatch(loginActions.isUserPresent({ user: userPresent }));
    }
  }, [dispatch]);

  useEffect(() => {
    fire.child("allOrders").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
        toast.error("No Data found");
      }
    });
    return () => {
      setData({});
    };
  }, []);

  useEffect(() => {
    fire.child("entAllOrders").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setEntData({ ...snapshot.val() });
      } else {
        setEntData({});
        toast.error("No Data found");
      }
    });
    return () => {
      setEntData({});
    };
  }, []);

  // const dataArray = [];

  useEffect(() => {
    const filteredOrder = Object.values(data).filter((filterOrder) => {
      return filterOrder.status === "Delivered";
    });

    const entFilteredOrder = Object.values(entData).filter((filterOrder) => {
      return filterOrder.status === "Delivered";
    });

    // dataArray.push(...filteredOrder);

    // dataArray.push(...entFilteredOrder);

    const reverseArrayList = (arr) => {
      arr.reverse();
      return arr;
    };
    const reverseActiveOrders = reverseArrayList(filteredOrder);
    const reverseDeliveredOrders = reverseArrayList(entFilteredOrder);

    setDeliveredOrders(reverseActiveOrders);
    setEntDeliveredOrders(reverseDeliveredOrders);

    setLoader(false);
    setGetOrder(true);
  }, [data, entData]);

  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }
  const showUserOrders = () => {
    setUserOrders(true);
    setEntOrders(false);
  };
  const showEntOrders = () => {
    setEntOrders(true);
    setUserOrders(false);
  };

  return (
    <>
      <Navbar />
      {loader && <Loader />}

      <div className={classes.separate}>
        <h2 onClick={showUserOrders}>Users</h2>
        <h2 onClick={showEntOrders}>Enterprise</h2>
      </div>

      {getOrder && (
        <div>
          {userOrders && (
            <div className={classes.container}>
              <h3>{deliveredOrders.length} Orders Completed in User Version</h3>
              <table className={classes.styledTable}>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>User</th>
                    <th>Date Time</th>
                    <th>Pickup</th>
                    <th>Pickup No</th>
                    <th>Pickup Ref</th>
                    <th>Drop </th>
                    <th>Drop No</th>
                    <th>Drop Ref</th>
                    <th>Package Ref</th>
                    <th>Secure Package</th>
                    <th>Status</th>
                    <th>Price</th>
                    <th>Delivery Boy</th>
                  </tr>
                </thead>

                <tbody>
                  {deliveredOrders.map((id, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{id.userEmail}</td>
                        <td>{id.dateTime}</td>
                        <td>{id.pickAdd}</td>
                        <td>{id.pickNo}</td>
                        <td>{id.pickRem}</td>
                        <td>
                          {truncate(id.dropAdd, 50)}
                          <br /> <br />
                          {truncate(id.dropAdd1, 50)}
                          <br /> <br />
                          {truncate(id.dropAdd2, 50)}
                        </td>{" "}
                        <td>{id.dropNo}</td>
                        <td>{id.dropRem}</td>
                        <td>{id.packRef}</td>
                        <td>{id.secPack}</td>
                        <td>{id.status}</td>
                        <td>
                          {id.rider}
                          <br />
                          <br />
                          {id.price}
                        </td>
                        <td>{id.deliveryBoy}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          {entOrders && (
            <div className={classes.container}>
              <h3>
                {entDeliveredOrders.length} Orders Completed in Enterprise
                Version
              </h3>
              <table className={classes.styledTable}>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>User</th>
                    <th>Date Time</th>
                    <th>Pickup</th>
                    <th>Pickup No</th>
                    <th>Pickup Ref</th>
                    <th>Drop</th>
                    <th>Drop No</th>
                    <th>Drop Ref</th>
                    <th>Package Ref</th>
                    <th>Secure Package</th>
                    <th>Status</th>
                    <th>Price</th>
                    <th>Delivery Boy</th>
                  </tr>
                </thead>
                <tbody>
                  {entDeliveredOrders.map((id, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{id.userEmail}</td>
                        <td>{id.dateTime}</td>
                        <td>{id.pickAdd}</td>
                        <td>{id.pickNo}</td>
                        <td>{id.pickRem}</td>
                        <td>
                          {truncate(id.dropAdd, 50)}
                          <br /> <br />
                          {truncate(id.dropAdd1, 50)}
                          <br /> <br />
                          {truncate(id.dropAdd2, 50)}
                        </td>{" "}
                        <td>{id.dropNo}</td>
                        <td>{id.dropRem}</td>
                        <td>{id.packRef}</td>
                        <td>{id.secPack}</td>
                        <td>{id.status}</td>
                        <td>
                          {id.rider}
                          <br />
                          <br />
                          {id.price}
                        </td>
                        <td>{id.deliveryBoy}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DeliveredOrder;
