import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../Admin/Edit.module.css";
import { fire } from "../Firebase";
import { toast } from "react-toastify";
import Navbar from "../Components/Navbar";

const initialState = {
  notification: "",
  pricing: "",
  duo: "",
  entDuo: "",
  rainNotification: "",
};
const EditPricing = () => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});
  const [clicked0, setClicked0] = useState(false);
  const [clicked1, setClicked1] = useState(false);
  const [clicked2, setClicked2] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const { notification, pricing, duo, entDuo, rainNotification } = state;

  useEffect(() => {
    fire.child(`dynamicUpdate`).on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }

    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!notification || !pricing) {
      toast.error("Please provide value in each input field");
    } else {
      fire.child(`dynamicUpdate/${id}`).set(state, (err) => {
        if (err) {
          toast.error(err);
        } else {
          toast.success("Pricing Updated Successfully");
        }
      });
      setTimeout(() => navigate("/surge"), 1000);
    }
  };

  const Rs13 = () => {
    setClicked0(!clicked0);
    setClicked1(false);
    setClicked2(false);
    state.pricing = "13";
  };
  const Rs15 = () => {
    setClicked1(!clicked1);
    setClicked0(false);
    setClicked2(false);
    state.pricing = "15";
  };
  const Rs17 = () => {
    setClicked2(!clicked2);
    setClicked0(false);
    setClicked1(false);
    state.pricing = "17";
  };

  return (
    <>
      <Navbar />
      <div style={{ marginTop: "100px" }}>
        <form onSubmit={submitHandler} className={classes.courierDetails}>
          <div className={classes.total}>
            <div
              style={{
                textAlign: "left",
                marginBottom: "20px",
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              <label className={classes.pick}>Order Status</label>
            </div>
            <button
              type="button"
              onClick={Rs13}
              style={{
                border: clicked0 ? "1px solid red" : "1px solid silver",
                fontWeight: "bold",
              }}
            >
              Rs 13
            </button>
            <button
              type="button"
              onClick={Rs15}
              style={{
                border: clicked1 ? "1px solid red" : "1px solid silver",
                fontWeight: "bold",
              }}
            >
              Rs 15
            </button>
            <button
              type="button"
              onClick={Rs17}
              style={{
                border: clicked2 ? "1px solid red" : "1px solid silver",
                fontWeight: "bold",
              }}
            >
              Rs 17
            </button>
          </div>
          <div className={classes.orderStatus}>
            <p className={classes.pick}>Solo Pricing</p>
            <input
              type="number"
              id="number"
              placeholder="Solo Pricing"
              className={classes.add}
              name="pricing"
              value={pricing || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.orderStatus}>
            <p className={classes.pick}>Duo Pricing</p>
            <input
              type="number"
              id="number"
              placeholder="Duo Pricing"
              className={classes.add}
              name="duo"
              value={duo || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.orderStatus}>
            <p className={classes.pick}>Ent. Duo Pricing</p>
            <input
              type="number"
              id="number"
              placeholder="Ent. Duo Pricing"
              className={classes.add}
              name="entDuo"
              value={entDuo || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.orderStatus}>
            <p className={classes.pick}>Primary Notifcation</p>
            <input
              type="text"
              id="text"
              placeholder="Primary Notification"
              className={classes.remarks}
              name="notification"
              value={notification || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.orderStatus}>
            <p className={classes.pick}>Rain Notifcation</p>
            <input
              type="text"
              id="text"
              placeholder="Rain Notification"
              className={classes.remarks}
              name="rainNotification"
              value={rainNotification || ""}
              onChange={handleInputChange}
            />
          </div>

          <div className={classes.total}>
            <button
              type="submit"
              style={{ backgroundColor: "#ff4141", color: "white" }}
            >
              Update Status
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditPricing;
