import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./Login/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditActiveOrder from "./Admin/EditActiveOrder";
import DeliveredOrder from "./Admin/DeliveredOrder";
import ActiveOrder from "./Admin/ActiveOrder";
import { useSelector } from "react-redux";
import EntEditActiveOrder from "./Admin/EntEditActiveOrder";
import EditPricing from "./Surge/EditPricing";
import Surge from "./Surge/Surge";
import User from "./Users/User";
import EntUser from "./Users/EntUser";
import Driver from "./Driver/Driver";
import Wallet from "./Wallet/Wallet";
import EditWallet from "./Wallet/EditWallet";
import EditDriver from "./Driver/EditDriver";
import Coupon from "./coupon/Coupon";

const App = () => {
  const loggedIn = useSelector((state) => state.loggedIn.loggedIn);

  return (
    <>
      <ToastContainer position="top-center" />
      <main>
        <Routes>
          <Route
            path="/"
            exact
            element={loggedIn ? <ActiveOrder /> : <Login />}
          />
          {loggedIn && <Route path="/active" element={<ActiveOrder />} />}{" "}
          {loggedIn && <Route path="/delivered" element={<DeliveredOrder />} />}{" "}
          {loggedIn && <Route path="/user" element={<User />} />}
          {loggedIn && <Route path="/entuser" element={<EntUser />} />}
          {loggedIn && (
            <Route path="/updateactive/:id" element={<EditActiveOrder />} />
          )}
          {loggedIn && (
            <Route
              path="/entupdateactive/:id"
              element={<EntEditActiveOrder />}
            />
          )}
          {loggedIn && <Route path="/surge" element={<Surge />} />}
          {loggedIn && (
            <Route path="/updatePricing/:id" element={<EditPricing />} />
          )}
          {loggedIn && <Route path="/drivers" element={<Driver />} />}
          {loggedIn && (
            <Route path="/editDriverWallet/:id" element={<EditDriver />} />
          )}
          {loggedIn && <Route path="/wallet" element={<Wallet />} />}
          {loggedIn && (
            <Route path="/editWallet/:id" element={<EditWallet />} />
          )}
          {loggedIn && <Route path="/coupons" element={<Coupon />} />}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </main>
    </>
  );
};

export default App;
