import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import classes from "./Navbar.module.css";
import { useSelector } from "react-redux";
import { loginActions } from "../Store/login";

const Navbar = () => {
  const loggedIn = useSelector((state) => state.loggedIn.loggedIn);
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();

  // toggle burger menu change
  const updateMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const logoutHandler = () => {
    dispatch(loginActions.loggedOut());
  };

  return (
    <div className={classes.nav}>
      <nav>
        <div className={classes.burger_menu} onClick={updateMenu}>
          <div
            className={classes.burger_icon}
            style={{
              transform: menuOpen
                ? "rotate(45deg) translate(0.75em, 1.25em)"
                : "rotate(0) translate(0)",
              transition: menuOpen
                ? "ease-out 0.5s"
                : "cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s",
            }}
          ></div>
          <div
            className={classes.burger_icon}
            style={{
              transform: menuOpen ? "scale(0.1)" : "rotate(0) translate(0)",
              transition: menuOpen
                ? "ease-out 0.5s"
                : "cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s",
            }}
          ></div>
          <div
            className={classes.burger_icon}
            style={{
              transform: menuOpen
                ? "rotate(135deg) translate(0.1em, 0.5em)"
                : "rotate(0) translate(0)",
              transition: menuOpen
                ? "ease-out 0.5s"
                : "cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s",
            }}
          ></div>
        </div>
        <div className={classes.items}>
          <ul>
            {loggedIn && (
              <li>
                <NavLink
                  className={(navigationData) =>
                    navigationData.isActive ? classes.active : null
                  }
                  to="/active"
                >
                  Active
                </NavLink>
              </li>
            )}
            {loggedIn && (
              <li>
                <NavLink
                  className={(navigationData) =>
                    navigationData.isActive ? classes.active : null
                  }
                  to="/delivered"
                >
                  Delivered
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </nav>

      <div
        style={{
          width: menuOpen ? "98.7vw" : "0vw",
          height: menuOpen ? "99.8vh" : "0vh",
        }}
        className={classes.menu}
      >
        <ul
          style={{ display: menuOpen ? "block" : "none" }}
          className={classes.sidebar_list}
        >
          <li>
            {" "}
            <NavLink
              className={(navigationData) =>
                navigationData.isActive ? classes.active : null
              }
              to="/surge"
            >
              Surge
            </NavLink>
          </li>
          <li>
            {" "}
            <NavLink
              className={(navigationData) =>
                navigationData.isActive ? classes.active : null
              }
              to="/user"
            >
              Users
            </NavLink>
          </li>
          <li>
            <NavLink
              className={(navigationData) =>
                navigationData.isActive ? classes.active : null
              }
              to="/entuser"
            >
              Ent Users
            </NavLink>
          </li>
          <li>
            <NavLink
              className={(navigationData) =>
                navigationData.isActive ? classes.active : null
              }
              to="/wallet"
            >
              Wallets
            </NavLink>
          </li>
          <li>
            <NavLink
              className={(navigationData) =>
                navigationData.isActive ? classes.active : null
              }
              to="/coupons"
            >
              Coupons
            </NavLink>
          </li>
          <li>
            <NavLink
              className={(navigationData) =>
                navigationData.isActive ? classes.active : null
              }
              to="/drivers"
            >
              Drivers
            </NavLink>
          </li>
          <li onClick={logoutHandler} className={classes.logout}>
            Logout
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
