import { configureStore } from "@reduxjs/toolkit";

// import logout from "./logout";
import login from "./login";
// import OrderSend from "./OrderSend";

const store = configureStore({
  reducer: { loggedIn: login.reducer },
});

export default store;
