import React, { useState, useEffect } from "react";
import classes from "../Admin/Edit.module.css";
import { fire } from "../Firebase";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "../Components/Loader";
import Navbar from "../Components/Navbar";

const Driver = () => {
  const [getOrder, setGetOrder] = useState(false);
  const [loader, setLoader] = useState(true);
  const [wallet, setWallet] = useState({});
  const [notification, setNotification] = useState([]);

  useEffect(() => {
    fire.child("deliveryPartners").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setWallet({ ...snapshot.val() });
      } else {
        setWallet({});
        toast.error("No Data found");
      }
    });
    return () => {
      setWallet({});
    };
  }, []);

  const surgedData = [];
  const totalSurgedData = [];

  useEffect(() => {
    const entryValue = Object.entries(wallet);
    for (const statuss of entryValue) {
      surgedData.push(statuss);
    }
    for (let i = 0; i < surgedData.length; i++) {
      const newOrderArrayKey = surgedData[i]?.[1];
      const newOrderArray = surgedData[i]?.[0];

      newOrderArrayKey.key = newOrderArray;
      totalSurgedData.push(newOrderArrayKey);
    }
    setNotification(totalSurgedData);
    console.log(totalSurgedData);

    setLoader(false);
    setGetOrder(true);
  }, [wallet]);

  return (
    <>
      <Navbar />
      {loader && <Loader />}

      {getOrder && (
        <div className={classes.notificationContainer}>
          <table className={classes.styledTable}>
            <thead>
              <tr className="headTr">
                <th>Sl.No</th>
                <th>Name</th>
                <th>Number</th>
                <th>Email</th>
                <th>Password</th>
                <th>Wallet</th>
                <th>Earning</th>
                <th>Approval</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {notification.map((id, index) => {
                return (
                  <tr key={id}>
                    <td>{index + 1}</td>
                    <td>{id.name}</td>
                    <td>{id.number}</td>
                    <td>{id.email}</td>
                    <td>{id.password}</td>
                    <td>{id.wallet}</td>
                    <td>{id.earning}</td>
                    <td>{id.approval}</td>
                    <td>
                      <Link to={`/editDriverWallet/${id.key}`}>
                        <button className={classes.editBtn}>Edit</button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Driver;
