import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const login = createSlice({
  name: "login",
  initialState: {
    loggedIn: false,
    username: "",
  },
  reducers: {
    userLogin(state, action) {
      state.username = action.payload.email;
      state.loggedIn = true;
      localStorage.setItem("username", state.username);
    },
    loggedOut(state) {
      localStorage.setItem("username", "");
      state.loggedIn = false;
      toast.error("Logged Out Successfully");
    },
    isUserPresent(state, action) {
      state.username = action.payload.user;
      state.loggedIn = true;
      if (state.username === "") {
        state.loggedIn = false;
      }
    },
  },
});

export const loginActions = login.actions;

export default login;
