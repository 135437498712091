import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import classes from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { loginActions } from "../Store/login";
import { toast } from "react-toastify";

const AdminSec = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const userPresent = localStorage.getItem("username");
    if (userPresent) {
      dispatch(loginActions.isUserPresent({ user: userPresent }));
    }
  }, [dispatch]);

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!email.includes("@")) {
      return toast.error("Enter Valid Email");
    }

    if (password.length < 6) {
      return toast.error("Password must be greater than 5 characters");
    }

    if (email.includes("@") && password.length >= 6) {
      setLoading(true);

      let url = process.env.REACT_APP_ADMIN_LOGIN;

      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          password: password,
          returnSecureToken: true,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        setLoading(false);
        if (res.ok) {
          dispatch(loginActions.userLogin({ email: email }));
          navigate("/adminDb");
        } else {
          return res.json().then((data) => {
            let errorMessage = "Authentication Failed";
            if (data && data.error && data.error.message) {
              errorMessage = data.error.message;
            }
            toast.error(errorMessage);
            throw new Error(errorMessage);
          });
        }
      });
    }
  };

  return (
    <>
      <div className={classes.SignLogin}>
        <img
          src="https://s3.ap-southeast-1.amazonaws.com/images.deccanchronicle.com/dc-Cover-b6s504cm9n74qn9rcp86j8aei7-20200105012040.Medi.jpeg"
          alt="Admin"
        />
        <div className={classes.signups}>
          <h2>Admin Panel</h2>

          <form onSubmit={submitHandler}>
            <div className={classes.inputs}>
              <input
                type="email"
                id="email"
                placeholder="Email"
                onChange={emailHandler}
                value={email}
              />
              <input
                type="password"
                id="password"
                placeholder="Password"
                onChange={passwordHandler}
                value={password}
              />
            </div>
            <div className={classes.btn}>
              <button type="submit">{loading ? "Loading..." : "Login"}</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminSec;
