import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "./Edit.module.css";
import { fire } from "../Firebase";
import { toast } from "react-toastify";
import Navbar from "../Components/Navbar";
import axios from "axios";

const initialState = {
  pickupTime: "",
  pickNo: "",
  pickRem: "",
  dropNo: "",
  dropRem: "",
  packRef: "",
  status: "",
  deliveryBoy: "",
  price: "",
  deliveryEmail: "",
  userEmail: "",
  secPack: "",
  fcm_token: "",
  cod: "",
};
const EditActiveOrder = () => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});
  // const [clicked0, setClicked0] = useState(false);
  // const [clicked1, setClicked1] = useState(false);
  // const [clicked2, setClicked2] = useState(false);
  // const [clicked3, setClicked3] = useState(false);
  const [clicked4, setClicked4] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [time, setTime] = useState(false);
  const [rider, setRider] = useState(false);
  const [mail, setMail] = useState(false);
  const [cash, setCash] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    pickAdd,
    pickupTime,
    pickNo,
    pickRem,
    dropAdd,
    dropNo,
    dropRem,
    dropAdd1,
    dropRem1,
    dropAdd2,
    dropRem2,
    packRef,
    status,
    deliveryBoy,
    price,
    deliveryEmail,
    userEmail,
    secPack,
    fcm_token,
    cod,
  } = state;

  //order se lelena
  // const fcmToken =
  //   "eAb2bVfLTTCyXCH8qGzkN1:APA91bFWelv6xQK8a8HgQ_aHOrchG8_3dVoVIqxQrjglZVaHm0UXgtpBs709XOC3-JxbFx6ma7eRHIp_5PD4v2ilfDn32eSO4NBBX7OnfTxmTVIei0UPWCoumXBl9cxKZknGkEFjaFoZ";
  const fcmUrl = "https://fcm.googleapis.com/fcm/send";

  // tera firebase ka server key
  const serverKey =
    "	AAAAH_wCCak:APA91bF4xHk1aAB3ub0izW3_z7eX9aSfdrtdRuJQnSOsORNj3JRNjgSusAZqr-vzitP_U7v7mVA4um8_A8PzuZG_eXpnSjIoNwmNNfBXgR4hKKEddh49Ojdlb32xc-Tg3V0EKbb8M0cw";

  useEffect(() => {
    fire.child(`activeOrders`).on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });
    return () => {
      setData({});
      // console.log(data);
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });
    } else {
      setState({ ...initialState });
    }

    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);
  // const token = state.fcmToken;
  // console.log(state);
  // console.log(state.fcm_token);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  // Notification

  const postNotification = (title, message, fcmToken) => {
    const payload = {
      to: fcmToken,
      notification: {
        title: title,
        text: message,
      },
    };

    axios
      .post(fcmUrl, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `key=${serverKey}`,
        },
      })
      .then(() => {
        console.log("notification sent");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!status || !pickNo || !dropNo || !deliveryBoy) {
      toast.error("Please provide value in each input field");
    } else {
      if (state.status === "Delivered") {
        if (window.confirm("Are you sure ?")) {
          fire.child(`allOrders`).push(state, (err) => {
            if (err) {
              toast.error(err);
            } else {
              postNotification(
                "Your Order has been Delivered.",
                "Check to see the Status",
                fcm_token
              );
              fire.child(`activeOrders/${id}`).remove();
              toast.success("Order Delivered Successfully");
              setTimeout(() => navigate("/active"), 1000);
            }
          });
        } else {
          setTimeout(() => navigate(`/updateactive/${id}`), 1000);
        }
      } else {
        fire.child(`activeOrders/${id}`).set(state, (err) => {
          if (err) {
            toast.error(err);
          } else {
            postNotification(
              "Your Order is on the way",
              "Check to see the Status",
              fcm_token
            );
            toast.success("Order Updated Successfully");
          }
        });
        setTimeout(() => navigate("/active"), 1000);
      }
    }
  };

  const deleteOrder = () => {
    if (window.confirm("Are you sure ?")) {
      fire.child(`activeOrders/${id}`).remove((err) => {
        if (err) {
          toast.error(err);
        } else {
          setTimeout(() => navigate("/active"), 500);
        }
      });
    }
  };

  const editMore = () => {
    setEdit(!edit);
  };

  // const onTheWay = () => {
  //   setClicked0(!clicked0);
  //   setClicked1(false);
  //   state.status = "On the Way";
  // };
  // const delivered = () => {
  //   setClicked1(!clicked1);
  //   setClicked0(false);
  //   state.status = "Delivered";
  // };
  let singleClickTimer;
  const handleClick = () => {
    if (singleClickTimer) {
      clearTimeout(singleClickTimer);
      handleDoubleClick();
    } else {
      // Start a timer to handle the single click
      singleClickTimer = setTimeout(() => {
        singleClickTimer = null; // Clear the timer
        state.status = "On the Way";
        setIsUpdated(true);
      }, 300); // Adjust the delay as needed (300 milliseconds here)
    }
  };
  const handleDoubleClick = () => {
    state.status = "Delivered";
    setIsUpdated(true);
  };

  useEffect(() => {
    // Clean up the single click timer when component unmounts
    return () => {
      if (singleClickTimer) {
        clearTimeout(singleClickTimer);
      }
    };
  }, []);

  const showTime = () => {
    setTime(!time);
    setRider(false);
    setMail(false);
    setCash(false);
  };
  const showRider = () => {
    setRider(!rider);
    setTime(false);
    setMail(false);
    setCash(false);
  };
  const showMail = () => {
    setMail(!mail);
    setTime(false);
    setRider(false);
    setCash(false);
  };
  const showCash = () => {
    setCash(!cash);
    setTime(false);
    setRider(false);
    setMail(false);
  };

  const revokeAccess = () => {
    setClicked4(true);
    state.deliveryEmail = "";
    state.deliveryBoy = "Not Assigned";
    state.status = "Placed";
  };

  return (
    <>
      <Navbar />
      <div>
        <form onSubmit={submitHandler} className={classes.courierDetails}>
          {/* <div className={classes.total}>
            <div
              style={{
                textAlign: "left",
                marginBottom: "20px",
                fontSize: "20px",
              }}
            >
              <label className={classes.pick}>Order Status</label>
            </div>
            <button
              type="button"
              onClick={onTheWay}
              style={{
                border:
                  clicked0 || state.status === "On the Way"
                    ? "1px solid red"
                    : "1px solid silver",
                fontWeight: "bold",
              }}
            >
              On The Way
            </button>
            <button
              type="button"
              onClick={delivered}
              style={{
                border: clicked1 ? "1px solid red" : "1px solid silver",
                fontWeight: "bold",
              }}
            >
              Delivered
            </button>
            
          </div> */}
          {/* <div className={classes.orderStatus} style={{ display: "none" }}>
            <p
              className={classes.pick}
              style={{
                marginBottom: "20px",
                fontSize: "20px",
              }}
            >
              Delivery Boy Status
            </p>{" "}
            <input
              type="text"
              id="text"
              placeholder="Current Order Status"
              className={classes.add}
              // ref={pickupAddRef}
              name="status"
              value={status || ""}
              onChange={handleInputChange}
            />
          </div> */}
          <div className={classes.orderStatus1}>
            <p>Order Status:</p>
            <button
              type="button"
              onClick={handleClick}
              onDoubleClick={handleDoubleClick}
              className={`${
                state.status === "On the Way" || isUpdated
                  ? classes.onTheWay
                  : ""
              } ${
                state.status === "Delivered" && isUpdated
                  ? classes.delivered
                  : ""
              }${state.status === "Placed" ? classes.placed : ""}
              `}
            ></button>
          </div>
          <div className={classes.orderStatus}>
            <div className={classes.orderStatusButtons}>
              <button type="button" onClick={showTime}>
                🕙
              </button>

              <button type="button" onClick={showRider}>
                🚴‍♀️
              </button>

              <button type="button" onClick={showMail}>
                📧
              </button>

              <button type="button" onClick={showCash}>
                💸
              </button>
            </div>

            <div className={classes.orderStatusInputs}>
              {time && (
                <input
                  type="text"
                  id="text"
                  placeholder="Pickup Time"
                  className={classes.remarks}
                  name="pickupTime"
                  value={pickupTime || ""}
                  onChange={handleInputChange}
                />
              )}
              {rider && (
                <input
                  type="text"
                  id="text"
                  placeholder="Delivery Boy Status"
                  className={classes.remarks}
                  name="deliveryBoy"
                  value={deliveryBoy || ""}
                  onChange={handleInputChange}
                />
              )}
              {mail && (
                <input
                  type="text"
                  id="text"
                  placeholder="Rider Email"
                  className={classes.remarks}
                  name="deliveryEmail"
                  value={deliveryEmail || ""}
                  onChange={handleInputChange}
                />
              )}
              {cash && (
                <input
                  type="text"
                  id="text"
                  placeholder="COD Value and Collection point"
                  className={classes.remarks}
                  name="cod"
                  value={cod || ""}
                  onChange={handleInputChange}
                />
              )}
            </div>
          </div>

          {/* Driver Access Button */}
          <div className={classes.access}>
            <button
              type="button"
              onClick={revokeAccess}
              style={{
                border: clicked4 ? "1px solid red" : "1px solid silver",
                fontWeight: "bold",
              }}
            >
              Revoke
            </button>
            <button
              type="submit"
              style={{ backgroundColor: "#ff4141", color: "white" }}
            >
              Update
            </button>
          </div>
          <div className={classes.multipleAddresses}>
            <p>
              <span style={{ fontWeight: "bold" }}>Pickup - </span>
              {pickAdd} <br /> <br />
              {pickRem}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Drop 1 - </span>
              {dropAdd} <br /> <br />
              {dropRem}
            </p>
            {dropAdd1?.length === 0 ? (
              ""
            ) : (
              <p>
                <span style={{ fontWeight: "bold" }}>Drop 2 - </span>
                {dropAdd1} <br /> <br />
                {dropRem1}
              </p>
            )}
            {dropAdd2?.length === 0 ? (
              ""
            ) : (
              <p>
                <span style={{ fontWeight: "bold" }}>Drop 3 - </span>
                {dropAdd2} <br /> <br />
                {dropRem2}
              </p>
            )}
          </div>

          {/* Extra Edit Access Button */}
          <div className={classes.total}>
            <button
              type="button"
              onClick={editMore}
              style={{ backgroundColor: "#ff4141", color: "white" }}
            >
              {edit ? "Show Less" : "Show More"}
            </button>
          </div>

          {edit && (
            <div className={classes.addresses}>
              <div className={classes.pickUp}>
                <p>
                  User Email: <strong>{userEmail}</strong>
                </p>
                <p>FCM Token: {fcm_token}</p>

                <p className={classes.pick}>Price</p>
                <div className={classes.numberContainer}>
                  <span>Rs</span>

                  <input
                    type="number"
                    id="number"
                    className={classes.number}
                    name="price"
                    value={price || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <p className={classes.pick}>Secure Amount</p>
                <div className={classes.numberContainer}>
                  <span>Rs</span>

                  <input
                    type="number"
                    id="number"
                    className={classes.number}
                    name="secPack"
                    value={secPack || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <p className={classes.pick}>10-digit Mobile Number</p>

                <div className={classes.numberContainer}>
                  <span>+91</span>
                  <input
                    type="number"
                    id="number"
                    className={classes.number}
                    name="pickNo"
                    value={pickNo || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <input
                  type="text"
                  id="text"
                  placeholder="Enter Remarks Ex: Landmark or any instructions for Pickup"
                  className={classes.remarks}
                  name="pickRem"
                  value={pickRem || ""}
                  onChange={handleInputChange}
                />
              </div>

              <div className={classes.drop}>
                <p className={classes.pick}>10-digit Mobile Number</p>
                <div className={classes.numberContainer}>
                  <span>+91</span>
                  <input
                    type="number"
                    id="number"
                    className={classes.number}
                    name="dropNo"
                    value={dropNo || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <input
                  type="text"
                  id="text"
                  placeholder="Enter Remarks Ex: Landmark or any instructions for Pickup"
                  className={classes.remarks}
                  name="dropRem"
                  value={dropRem || ""}
                  onChange={handleInputChange}
                />
              </div>

              <div className={classes.packageType}>
                <input
                  type="text"
                  placeholder="What are you sending? (Note: We deliver Cakes upto 1kg only)"
                  name="packRef"
                  value={packRef || ""}
                  onChange={handleInputChange}
                />
                <p>Docs</p> <p>Meds</p> <p>Food</p>
                <br />
                <br />
                <button type="button" onClick={deleteOrder}>
                  Delete Order
                </button>
              </div>
              {/* <div className={classes.packageType}>
                <label>Payment Point</label>

                <input
                  type="text"
                  id="text"
                  placeholder="Select Payment Point"
                  className={classes.remarks}
                  name="payment"
                  value={payment || ""}
                  onChange={handleInputChange}
                />
               
              </div> */}
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default EditActiveOrder;
