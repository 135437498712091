import React, { useState, useEffect } from "react";
import classes from "../Admin/Edit.module.css";
import { fire } from "../Firebase";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from "../Components/Loader";
import Navbar from "../Components/Navbar";

const Surge = () => {
  const [getOrder, setGetOrder] = useState(false);
  const [loader, setLoader] = useState(true);
  const [surge, setSurge] = useState({});
  const [notification, setNotification] = useState([]);

  useEffect(() => {
    fire.child("dynamicUpdate").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setSurge({ ...snapshot.val() });
      } else {
        setSurge({});
        toast.error("No Data found");
      }
    });
    return () => {
      setSurge({});
    };
  }, []);

  const surgedData = [];
  const totalSurgedData = [];

  useEffect(() => {
    const entryValue = Object.entries(surge);
    for (const statuss of entryValue) {
      surgedData.push(statuss);
    }
    for (let i = 0; i < surgedData.length; i++) {
      const newOrderArrayKey = surgedData[i]?.[1];
      const newOrderArray = surgedData[i]?.[0];

      newOrderArrayKey.key = newOrderArray;
      totalSurgedData.push(newOrderArrayKey);
    }
    setNotification(totalSurgedData);

    setLoader(false);
    setGetOrder(true);
  }, [surge]);

  return (
    <>
      <Navbar />
      {loader && <Loader />}

      {getOrder && (
        <div className={classes.notificationContainer}>
          <table className={classes.styledTable}>
            <thead>
              <tr className="headTr">
                <th>Timing</th>
                <th>Solo </th>
                <th>Duo </th>
                <th>Ent Duo</th>
                <th>Rain </th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {notification.map((id, index) => {
                return (
                  <tr key={id}>
                    <td>{id.notification}</td>
                    <td>{id.pricing}</td>
                    <td>{id.duo}</td>
                    <td>{id.entDuo}</td>
                    <td>{id.rainNotification}</td>
                    <td>
                      <Link to={`/updatePricing/${id.key}`}>
                        <button className={classes.editBtn}>Edit</button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Surge;
